class FormCheck {

  constructor() {
    if (document.querySelector('.js-formCheck1') != null) {
      this.el = document.querySelectorAll('.js-formCheck1');
      this.button = document.querySelector('.p-block1__form1__main1 button[type="submit"]');
      this.buttonFlag = [];
      this.initialize();
      this.checkSubLabelFlg();
      this.checkButtonFlg();
    }
  }

  initialize() {
    for (let i = 0; i < this.el.length; i++) {

      let el = this.el[i];
      let primaryInput = el.querySelector('input');
      let secondaryList = el.querySelectorAll('.c-formCheck1__list1');
      let secondaryLabel = el.querySelectorAll('.c-formCheck1__list1 label');
      let secondaryInput = el.querySelectorAll('.c-formCheck1__list1 input');

      el.addEventListener("click", (e) => {
        primaryInput.checked = !primaryInput.checked;
        this.checkButtonFlg();

        if (!primaryInput.checked) {
          // 親のチェックがはずれた時の処理
          e.target.setAttribute('aria-expanded', 'false');
          for (let l = 0; l < secondaryLabel.length; l++) {
            secondaryLabel[l].previousElementSibling.checked = false;
          }
          secondaryList.forEach(el => {
            el.setAttribute('aria-hidden', 'true');
          });
        } else {
          // 親のチェックがついた時の処理
          e.target.setAttribute('aria-expanded', 'true');
          secondaryList.forEach(el => {
            el.setAttribute('aria-hidden', 'false');
          });
        }
      });

      // サブラベルを押したときの処理
      if (secondaryLabel.length !== 0) {
        for (let j = 0; j < secondaryLabel.length; j++) {
          secondaryLabel[j].addEventListener("click", (e) => {
            const el = secondaryLabel[i];
            e.stopPropagation();
            primaryInput.checked = true;
            this.checkButtonFlg();
          });
        }
      }

      // ラベルを押してもinputからクリックイベントが発火するので消す
      if (secondaryInput.length !== 0) {
        for (let k = 0; k < secondaryInput.length; k++) {
          secondaryInput[k].addEventListener("click", (e) => {
            e.stopPropagation();
          });
        }
      }
    }
  }

  checkButtonFlg() {
    // 検索ボタンが押せるかどうかの状態を確認して、ボタンの状態を変更
    this.buttonFlag = [];
    for (let i = 0; i < this.el.length; i++) {
      const el = this.el[i].querySelector('input').checked;
      this.buttonFlag.push(el);
    }

    if (this.buttonFlag.includes(true)) {
      this.button.classList.remove('-disabled');
    } else {
      this.button.classList.add('-disabled');
    }
  }

  checkSubLabelFlg() {
    // 初回表示時に、親inputがチェックされていたらサブinput表示
    this.el.forEach(el => {
      let primaryInput = el.querySelector('input');
      let secondaryList = el.querySelectorAll('.c-formCheck1__list1');
      if (primaryInput.checked) {
        el.setAttribute('aria-expanded', 'true');
        secondaryList.forEach(el => {
          el.setAttribute('aria-hidden', 'false');
        })
      }
    });
  }
}

export default FormCheck;
