class SearchFilter {

  constructor() {
    if (document.querySelector('.p-block1') != null) {
      this.button = document.querySelector('button[aria-controls="search"]');
      this.body = document.querySelector('#' + this.button.getAttribute('aria-controls'));
      this.initialize();
    }
  }

  initialize() {
    this.button.addEventListener("click", () => {
      const expanded = this.button.getAttribute("aria-expanded") === "true";
      this.button.setAttribute("aria-expanded", String(!expanded));
      this.body.classList.toggle('-active');
      if (this.body.classList.contains('-active')) {
        this.body.scrollIntoView();
      }
    });
  }

}

export default SearchFilter;
