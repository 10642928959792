import MobileMenu from "./modules/mobileMenu";
import SearchFilter from "./modules/searchFilter";
import FormCheck from "./modules/formCheck";

const init = () => {
  new MobileMenu();
  new SearchFilter();
  new FormCheck();
};

window.addEventListener("DOMContentLoaded", () => {
  init();
});
