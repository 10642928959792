class MobileMenu {

  constructor() {
    if (document.querySelector('.p-header1')) {
      this.mediaQueryList = window.matchMedia("(max-width: 1024px)");
      this.button = document.querySelector('button[aria-controls="p-nav1__main1"]');
      this.menu = this.button.nextElementSibling;

      //リサイズハンドリング
      this.mediaQueryList.addEventListener("change", this.initialize.bind(this));

      //初回アップデート
      this.initialize();
      this.update();
    }
  }

  initialize() {
    if (this.mediaQueryList.matches) {
      this.button.setAttribute("aria-expanded", "false");
      this.button.hidden = false;
      return;
    }

    this.button.removeAttribute("aria-expanded");
    this.button.hidden = true;
  }

  update() {
    this.button.addEventListener("click", function () {
      // メニューの表示／非表示を切り替える
      const expanded = this.getAttribute("aria-expanded") === "true";
      this.setAttribute("aria-expanded", String(!expanded));
    });
  }
}

export default MobileMenu;
